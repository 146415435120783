import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue11({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="New client, office makeover, charity donations and more"
            pageSocialImage=""
            pageTitle="Issue #11"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #11"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "It’s been a while, but I’m starting my first “client” project this month. What I mean is, that I typically work with agencies or other teams and I don’t normally work directly with the end client. I only take on projects with end clients if the project is interesting and they have a suitable budget.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at March"
                    content={[
                        {spans: [], text: "👷‍♂️ Work", type: "heading4"},
                        {spans: [], text: "This month has been fairly busy, I started work on a new marketing site for Etcho and carried on with my contract project.", type: "paragraph"},
                        {spans: [], text: "The contract project was due to finish at the end of March, but none of the leads that came in last month really panned out – a bunch of the leads never replied or I passed it on as the projects weren’t as interesting as the work I had been doing.", type: "paragraph"},
                        {spans: [], text: "The agency I’ve been working with since last September wants to keep working with me for as long as they can and so we decided to do a rolling monthly contract if either party wants to end it then we just need to give each other a month notice.", type: "paragraph"},
                        {spans: [], text: "They’re a great team to work with, the projects are interesting and it’s all using technologies that are good to know so it’s a win-win.", type: "paragraph"},
                        {spans: [], text: "It also means I can be more selective with the projects I take on as I don’t really need to take on any new projects alongside this part-time work.", type: "paragraph"},
                        {spans: [], text: "👨‍🎨 Office makeover", type: "heading4"},
                        {spans: [], text: "This kind of came out of the blue, but I decided to paint my office. For the past 3 years, I’ve had a green feature wall, but I decided to paint the other 3 walls the same colour and get some new plants – I’m now well on my way to working in a jungle.", type: "paragraph"},
                        {spans: [{
                            start: 19,
                            end: 29,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.instagram.com/p/CbMt9QtgAip/",
                            },
                        }], text: "I posted a shot on Instagram of what my updated office looks like if you want to see it.", type: "paragraph"},
                        {spans: [], text: "🏦 Swapping banks", type: "heading4"},
                        {spans: [], text: "One of my goals last month was to convince my wife to move our joint account away from HSBC to Monzo. This was largely driven by environmental reasons.", type: "paragraph"},
                        {spans: [{
                            start: 85,
                            end: 135,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://bank.green/banks/hsbc",
                            },
                        }], text: "I knew that HSBC didn’t do good things with our money but I hadn’t realised they are ranked second in the UK for financing fossil fuels.", type: "paragraph"},
                        {spans: [], text: "Well, that was the ammunition I needed to convince my wife and so we have created a Monzo joint account. We still need to move our direct debits etc. over but it’s a good first step.", type: "paragraph"},
                        {spans: [], text: "🎗 New charity donation", type: "heading4"},
                        {spans: [{
                            start: 157,
                            end: 170,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.akshayapatra.org/",
                            },
                        }], text: "Off the back of one of my investments last month (Stem & Glory), they shared that they had donated 18,000 meals to those in need in India and the UK through Akshaya Patra.", type: "paragraph"},
                        {spans: [], text: "This is exactly the kind of thing I’d support and so I set up a small monthly donation to help feed hungry children too.", type: "paragraph"},
                        {spans: [], text: "🌱 Growing food", type: "heading4"},
                        {spans: [], text: "Over the past 3 years, my wife and I have been growing a few small herbs and vegetables in our garden.", type: "paragraph"},
                        {spans: [], text: "Long story short, we’ve got some space at an allotment to grow our own vegetables and so we’ll fail and learn a lot this year.", type: "paragraph"},
                        {spans: [], text: "I’m excited to get my daughter involved and develop an understanding of where food comes from and continue having her help me cook food.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 March goals"
                    checkList={[
                        {complete: false, text: "💵 Keep total monthly spend below £3k"},
                        {complete: true, text: "💳 Persuade my wife to move over to Monzo joint account"},
                        {complete: true, text: "🧭 Create a content plan for Stryve"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to April"
                    content={[
                        {spans: [], text: "April is set to be a very repetitive month, I’ll basically be working on 2 projects – my contract project and Etcho.", type: "paragraph"},
                        {spans: [], text: "All other clients I do work with regularly will have to wait until May before I can help them.", type: "paragraph"},
                        {spans: [], text: "This does mean I’ll have to break my 4 day work week but it was for the right project.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 April goals"
                    checkList={[
                        {complete: false, text: "🥾 Finish Etcho project"},
                        {complete: false, text: "☀️ Find a hosting provider that runs on renewable energy"},
                        {complete: false, text: "☁️ Keep Etcho carbon footprint below 0.3g per page view"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Try to get into a position where you have more control over the projects you take on, instead of saying yes to anything that comes your way."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/bd28bcd4-47b4-40b0-8611-5f181dc40835_income-mar-2022.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £7k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/d4e05af4-b469-4827-b8fc-d444cd4c17be_asparagus-fern.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Asparagus fern", type: "heading4"},
                        {spans: [], text: "I bought this asparagus fern for my office and I’ve got to admit it’s quickly become my favourite plant. It’s such a vibrant green and there’s something about the needles that make it stand out amongst my other plants.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://thestem.co.uk/indoor-plants/asparagus-fern",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/7dac5b97-546b-4565-9309-10dcd15fead8_start-with-why.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Start with why", type: "heading4"},
                        {spans: [], text: "This book has been on my wishlist for a while now, but it felt like the right time to listen to it. I’ve been at a cross-road where I want to take my business for some time, instead of just what I’m doing I’ve come to a point where why I want to do something is a powerful driving force.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Start-Why-Leaders-Inspire-Everyone-ebook/dp/B005JZD3B4/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue11;
